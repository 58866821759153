import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { OPERATION_ID_ENUM } from '@app/enums/post-section/operation_id.enum';
import {
    ResponseAllPostBoxNew,
    ResponseAllPostBoxPublic,
    ResponseAllPublication,
    ResponseMyPostBox,
    ResponseNotPublished,
    ResponseOnePublication,
    ResponseOnePublicationPublic,
    ResponsePostBoxUser,
    ResponsePublicationMap,
    ResponsePublicationType,
    ResponseSetPublication,
} from '@app/models/post-box/responsePostBox.model';
import { DtoListHttp } from '@app/models/dto-list-http.model';
import { DtoReportPublication } from '@app/models/post-box/dtoReportPublication.model';
import { DtoAllPostBoxNew } from '@app/models/post-box/dtoListPostBoxHttp.model';
import { DtoPublicationMap } from '@app/models/post-box/dtoPublicationMap.model';
import { DtoSumShare } from '@app/models/post-box/dtoSumShare.model';
import { DtoDeletePicturePublication } from '@app/models/post-box/dtoDeletePicturePublication.model';
import { DtoStyleBox } from '@app/models/post-box/dtoStyleBox.model';

@Injectable({
    providedIn: 'root',
})
export class PostService {
    private readonly API_URI = environment.PROXY
        ? '/api/'
        : environment.API_URL_V1;
    private http = inject(HttpClient);

    allPostBoxNew(dto: DtoAllPostBoxNew): Observable<ResponseAllPostBoxNew> {
        let params = new HttpParams();
        params = params.append('skip', dto.skip);
        params = params.append('limit', dto.limit);
        params = params.append('needFollowers', dto.needFollowers);

        return this.http.get<ResponseAllPostBoxNew>(
            `${this.API_URI}post-box/home`,
            {
                params,
            }
        );
    }

    allPostBoxPublic(dto: DtoListHttp): Observable<ResponseAllPostBoxPublic> {
        let params = new HttpParams();
        params = params.append('skip', dto.skip);
        params = params.append('limit', dto.limit);

        return this.http.get<ResponseAllPostBoxPublic>(
            `${this.API_URI}post-box/all-public`,
            {
                params,
            }
        );
    }

    reportPublication(dto: DtoReportPublication): Observable<void> {
        return this.http.post<void>(
            `${this.API_URI}publication/report-publication`,
            dto
        );
    }

    myPostBox(): Observable<ResponseMyPostBox> {
        return this.http.get<ResponseMyPostBox>(`${this.API_URI}post-box`);
    }

    publicationType(
        id: OPERATION_ID_ENUM
    ): Observable<ResponsePublicationType> {
        return this.http.get<ResponsePublicationType>(
            `${this.API_URI}publication/type/${id}`
        );
    }

    allPublication(): Observable<ResponseAllPublication> {
        return this.http.get<ResponseAllPublication>(
            `${this.API_URI}publication`
        );
    }

    postBoxUser(data: string): Observable<ResponsePostBoxUser> {
        return this.http.get<ResponsePostBoxUser>(
            `${this.API_URI}post-box/${data}`
        );
    }

    onePublication(id: string): Observable<ResponseOnePublication> {
        return this.http.get<ResponseOnePublication>(
            `${this.API_URI}publication/${id}`
        );
    }

    onePublicationPublic(id: string): Observable<ResponseOnePublicationPublic> {
        return this.http.get<ResponseOnePublicationPublic>(
            `${this.API_URI}publication/public/${id}`
        );
    }

    notPublished(): Observable<ResponseNotPublished> {
        return this.http.get<ResponseNotPublished>(
            `${this.API_URI}publication/not-published`
        );
    }

    publicationMap(dto: DtoPublicationMap): Observable<ResponsePublicationMap> {
        return this.http.post<ResponsePublicationMap>(
            `${this.API_URI}post-box/maps`,
            { data: dto }
        );
    }

    setPublication(data: FormData): Observable<ResponseSetPublication> {
        return this.http.post<ResponseSetPublication>(
            `${this.API_URI}publication`,
            data,
            {
                headers: { 'Cache-Control': 'no-cache' },
            }
        );
    }

    setStyleBox(dto: DtoStyleBox): Observable<void> {
        return this.http.patch<void>(`${this.API_URI}post-box`, dto);
    }

    updatePublication(data: FormData): Observable<void> {
        return this.http.patch<void>(`${this.API_URI}publication`, data, {
            headers: { 'Cache-Control': 'no-cache' },
        });
    }

    deletePicturePublication(
        dto: DtoDeletePicturePublication
    ): Observable<void> {
        return this.http.delete<void>(`${this.API_URI}publication/images`, {
            body: dto,
        });
    }

    deletePublication(id: string): Observable<void> {
        return this.http.delete<void>(`${this.API_URI}publication/${id}`, {
            body: { id },
        });
    }

    updateStatusPublication(): Observable<void> {
        return this.http.put<void>(`${this.API_URI}publication`, {
            body: {},
        });
    }

    sentLike(publicationId: string): Observable<void> {
        return this.http.patch<void>(`${this.API_URI}like-publication`, {
            publicationId,
        });
    }

    sumShare(dto: DtoSumShare): Observable<void> {
        return this.http.patch<void>(
            `${this.API_URI}publication/sum-share`,
            dto
        );
    }

    sentPostBoxLike(postBoxId: string): Observable<void> {
        return this.http.patch<void>(`${this.API_URI}post-box/like`, {
            postBoxId,
        });
    }

    sentPostBoxShare(postBoxId: string): Observable<void> {
        return this.http.patch<void>(`${this.API_URI}post-box/share`, {
            postBoxId,
        });
    }

    getPublicationType(data: { id: OPERATION_ID_ENUM }): Observable<any> {
        return this.http.get<any>(
            `${this.API_URI}publication/type/${data.id}`,
            {
                headers: { opc: '1' },
            }
        );
    }
}
